/**
** JAVASCRIPTS
** Name: Player
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-player') && document.querySelectorAll('.js-track')) {


    /**
    ** Variables
    ****************************************/

    const $html   = document.querySelector('html');
    const $body   = document.querySelector('body');
    const $player = document.querySelector('.js-player');
    const $tracks = document.querySelectorAll('.js-track');

    const $artist = $player.querySelector('.js-player-artist');
    const $title  = $player.querySelector('.js-player-title');
    const $genre  = $player.querySelector('.js-player-genre');
    const $prefix = $player.querySelector('.js-player-prefix');
    const $date   = $player.querySelector('.js-player-date');
    const $audio  = $player.querySelector('.js-player-audio');

    const $plays  = document.querySelectorAll('.js-player-play');
    const $prev   = $player.querySelector('.js-player-prev');
    const $next   = $player.querySelector('.js-player-next');

    var player    = [];
    var current   = 0;
    var isPlaying = false;


    /**
    ** Get audios
    ****************************************/

    $tracks.forEach(function($track) {
      player.push({
        upc: $track.dataset.upc,
        artist: $track.dataset.artist,
        title: $track.dataset.title,
        genre: $track.dataset.genre,
        date: $track.dataset.date,
        released: $track.dataset.released,
        audio: $track.dataset.audio
      });
    });


    /**
    ** Actions
    ****************************************/

    if(player.length > 0) {


      /**
      ** FUNCTION
      ** Name: Set player infos
      ****************************************/

      function setPlayerInfos() {
        $artist.innerHTML = player[current].artist;
        $title.innerHTML = player[current].title;
        $genre.innerHTML = player[current].genre;
        $prefix.innerHTML = player[current].released ? "Released on " : "To be released on ";
        $date.innerHTML = player[current].date;

        if(player[current].released) {
          $player.classList.remove('is-tobereleased');
        }
        else {
          $player.classList.add('is-tobereleased');
        }

        $audio.setAttribute('src', player[current].audio);
      }


      /**
      ** FUNCTION
      ** Name: Stop audio
      ****************************************/

      function stopAudio() {
        $audio.currentTime = 0;
        $html.classList.remove('is-playing');
        document.querySelector('.js-track[data-upc="' + player[current].upc + '"]').classList.remove('is-playing');

        if(player[current + 1] !== undefined) {
          current += 1;
          setPlayerInfos();
          playAudio();
        }
        else {
          current = 0;
          setPlayerInfos();
          $html.classList.remove('is-playing');
          isPlaying = false;
        }
      }


      /**
      ** FUNCTION
      ** Name: Play audio
      ****************************************/

      function playAudio() {
        $audio.play();
        $html.classList.add('is-playing');
        document.querySelector('.js-track[data-upc="' + player[current].upc + '"]').classList.add('is-playing');
        isPlaying = true;
      }


      /**
      ** FUNCTION
      ** Name: Pause audio
      ****************************************/

      function pauseAudio() {
        $audio.pause();
        $html.classList.remove('is-playing');
        document.querySelector('.js-track[data-upc="' + player[current].upc + '"]').classList.remove('is-playing');
        isPlaying = false;
      }


      /**
      ** FUNCTION
      ** Name: Prev audio
      ****************************************/

      function prevAudio() {
        document.querySelector('.js-track[data-upc="' + player[current].upc + '"]').classList.remove('is-playing');

        if(player[current - 1] !== undefined) {
          current -= 1;
          setPlayerInfos();
        }
        else {
          current = player.length - 1;
          setPlayerInfos();
        }

        if(isPlaying) {
          playAudio();
        }
      }


      /**
      ** FUNCTION
      ** Name: Next audio
      ****************************************/

      function nextAudio() {
        document.querySelector('.js-track[data-upc="' + player[current].upc + '"]').classList.remove('is-playing');

        if(player[current + 1] !== undefined) {
          current += 1;
          setPlayerInfos();
        }
        else {
          current = 0;
          setPlayerInfos();
        }

        if(isPlaying) {
          playAudio();
        }
      }


      /**
      ** Play button
      ****************************************/

      $plays.forEach(function($play) {
        $play.addEventListener('click', (event)=>{

          if(!isPlaying) {

            if($play.hasAttribute('data-index') && $play.dataset.index == "true") {
              const trackUpc   = $play.closest('.js-track').dataset.upc;
              const trackIndex = player.findIndex(track => track.upc == trackUpc);
              current = trackIndex;
              setPlayerInfos();
            }

            playAudio();
          }

          else {
            pauseAudio();

            if($play.hasAttribute('data-index') && $play.dataset.index == "true") {
              const trackUpc   = $play.closest('.js-track').dataset.upc;
              const trackIndex = player.findIndex(track => track.upc == trackUpc);

              if(trackIndex != current) {
                current = trackIndex;
                setPlayerInfos();
                playAudio();
              }
            }

          }

        });
      });


      /**
      ** Prev button
      ****************************************/

      $prev.addEventListener('click', (event)=>{
        prevAudio();
      });


      /**
      ** Next button
      ****************************************/

      $next.addEventListener('click', (event)=>{
        nextAudio();
      });


      /**
      ** EVENT LISTENER
      ** Name: Audio ended
      ****************************************/

      $audio.addEventListener("ended", stopAudio, true);


      /**
      ** INIT
      ****************************************/

      setPlayerInfos();


    }


    /**
    ** Remove player if no songs
    ****************************************/

    else {
      $player.remove();
    }


  }

})();
