/**
** JAVASCRIPTS
** Name: Slider
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import "../../vendors/slick.min.js";


/**
** Scripts
****************************************/

(function() {
  'use strict';

  if($('.js-slider').length > 0) {


    /**
    ** Document
    ****************************************/

    $(document).ready(function() {


      /**
      ** Loop
      ****************************************/
      
      $('.js-slider-items').each(function() {


        /**
        ** Variables
        ****************************************/

        var $items  = $(this);
        var $slider = $items.parents('.js-slider');
        var $prev   = $slider.find('.js-slider-prev');
        var $next   = $slider.find('.js-slider-next');


        /**
        ** Slick
        ****************************************/

        $items.slick({
          arrows: false,
          dots: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 719,
              settings: {
                slidesToShow: 2
              }
            }
          ]
        });


        /**
        ** Prev
        ****************************************/

        $prev.on('click', function() {
          var slick = $items.slick('getSlick');

          if(slick.currentSlide == 0) {
            $items.slick('slickGoTo', slick.slideCount - slick.options.slidesToShow);
          }
          else {
            $items.slick('slickPrev');
          }
        });


        /**
        ** Next
        ****************************************/

        $next.on('click', function() {
          var slick = $items.slick('getSlick');

          if(slick.currentSlide == slick.slideCount - slick.options.slidesToShow) {
            $items.slick('slickGoTo', 0);
          }
          else {
            $items.slick('slickNext');
          }
        });


      });
    });
  }
})();
