/**
** JAVASCRIPTS
** Name: Menu
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-menu')) {


    /**
    ** Variables
    ****************************************/

    const $html = document.querySelector('html');
    const $body = document.querySelector('body');
    const $menu = document.querySelector('.js-menu');


    /**
    ** Events
    ****************************************/

    $menu.addEventListener('click', function(event) {
      $html.classList.toggle('menu-active');
    });


  }

})();
